<template>
  <div>
    <v-card flat>
      <div class="tw-grid justify-items-stretch">
        <div class="tw-justify-self-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="406px"
            height="793px"
          >
            <g id="svgg">
              <path
                stroke="none"
                :fill="color9"
                fill-rule="evenodd"
                d="M387.1 510.1
        c2.047 21.552-2.641 29.894-18 36.9-11.279-5.011-22.842-4.628-30.1-14-7.995-10.323-14.601-30.744-18-44 .1-10.999 2-22.001 2.1-33.9-1.117-10.87-24.356-63.38-31.1-71.1-.667.667-1.333 1.333-2.9 1.1 4.566 10.232 8.234 19.568 11.9 28 4.801 20.445-.904 48.058-4.9 63.9.567 10.999.233 22.001-1 32.1-2.766 20.565-6.434 40.235-10.1 59.9-4.239 12.398-14.161 27.388-17.9 38.1-.441 6.385 3.876 12.284 3 19-.433 12.565-1.767 24.235-3.1 35.9 2.604 17.5 10.933 40.809 6 64-9.288 43.668-24.424 95.113-16 137h-1
        c-9.757-4.562-28.658-3.158-43.9-3-.1 7.333-2 14.667-2.1 21.1 1.023 3.7 3.228 3.855 3.1 8.9h-1
        c.233-.333-.433-.667-1.1-1-1.357-2.962-3.64-5.089-6.9-7.9.972-10.276.015-27.729 2-42 2.363-5.879 6.777-12.698 4.9-23.1-1.333-17.332-2.667-34.668-4.9-52-1.766-10.999-4.434-22.001-8-33-3.612-22.207 9.071-41.214 5-59-6.016-38.792-9.251-86.857-12.1-129.9-1.333 1.233-2.667 1.567-4.9 1-1.1 10.566-4 20.234-5.1 29.9v71
        c-.029 11.958-6.645 19.281-4.9 29.1 3.499 11.432 7.982 22.342 9 34.9 4.938 26.436-8.963 56.176-15 80.1-5.946 33.983 18.774 76.549 7 95.9-6.023 2.56-11.04 6.812-19.1 9 2.281-8.042 14.23-9.696 18.1-16.9 1.131-6.358-1.025-20.558-6-24.1H157
        c-28.541.069-22.981 16.237-40.9 20.1 2.969-8.165 12.968-14.481 18-20.1-2.42-10.055.441-18.414 0-28-3.433-15.332-7.767-30.668-12.1-46-5.494-20.151-6.242-41.132-10-61-5.084-26.876 10.783-59.99 6-79-2.702-10.739-1.832-26.197-5.9-39.9-2.766-12.765-6.434-26.435-10.1-41a13417.23 13417.23 0 01-8-83
        c-6.254-40.131 8.113-76.595 9.1-107 1.672-11.168 13.285-38.083 4-47v2.9
        c-1.766 16.998-4.434 34.002-7.1 51-3.119 11.128-4.181 25.446-8 38l-18 45
        c1.1 5.333 4 10.667 5.1 15.1 3.637 11.372-1.251 21.453-3.1 26.9-2 9.332-4.9 18.668-6.9 28-5.779 16.116-32.478 20.806-48.1 11.1-1.933-6.841-3.048-15.112-3-27-1 .567-2.9.233-3.9-1-4.407 4.925-8.558 5.427-14.1 1v-1
        c2.874-15.649 12.383-34.329 22-46 3.485-3.003 8.09-4.327 9.1-10.1 7.358-19.516 8.75-37.471 12-59.9 4.798-19.325-.061-36.93 4.9-56 1.751-5.515 6.606-14.351 8-20.1 2.204-9.089-3.832-31.037-3.9-42 2-15.998 4.9-32.002 6-48.9-.508-13.276-4.556-30.383 1-43.1 16.809-31.34 87.794-32.792 96.9-62.9v-25
        c-9.303-3.894-21.681-20.778-18-36 .888-2.556 5.036-4.686 5.1-8 4.298-7.611-.983-18.472 2.9-28 8.027-16.937 38.564-29.047 65-23 12.872 4.283 25.905 12.915 29.1 24.9 4.362 10.134-3.744 23.495-1 28 5.772 3.563 7.217 5.126 9.9 10.1-1.333 6.899-2.667 12.901-4 18-6.619 15.508-21.666 21.163-16 45.9 20.374 5.983 38.542 20.52 56.1 28 18.366 7.078 30.395 5.745 39 19.1 8.414 12.17 12.011 37.542 8 57 .233 4.566-.433 8.234-2 11.9 2.466 6.328 5.323 16.736 6.9 24v37
        c4.779 13.896 13.602 29.05 18 43.1 6.953 24.535-2.725 51.684 4.1 73 2 4.566 4.9 8.234 6.9 11 .1 7.566 2 14.234 2.1 20.9 4.233 1.1 7.567 4 10 5.1 8.309 7.354 32.425 41.822 26 52h-17zm-193-506
        c1.233 41.229 1.567 81.571 1 121h-6v-1
        c1.567-38.763 2.233-78.437 2.9-119-21.062-1.377-39.27 12.881-44 30.9.333 8.332.667 16.667 1 24.1-3.445-.472-3.102-.544-7.9-1 .568 15.012 5.052 21.612 10 31.9 5.63-.739 4.263-2.452 6.9 0 3.012 3.667 5.695 15.9 7 19.1-3.273.511-2.441-.223-5.9 1 9.002 29.67-30.369 30.62-44 42 52.561.567 104.239.233 155-1-5.488-.927-8.737-5.702-14.1-9-19.172-7.674-33.917-6.74-34.9-29.1-.767.333-2.434.667-5 1 3.566-3.333 6.234-6.667 8-10 3.233-6.333 5.567-12.667 7-19 5.699 1.354 5.272-.519 9-4 1.706-9.941 5.537-15.849 0-24.9-4.989 1.335-3.939 2.406-9 3 11.584-31.768-.243-54.527-37-56zm-5 155
        c-45.917-.069-102.435-6.107-122 21-5.366 10.303-11.383 30.409-3.1 42 42.329.233 84.671-.433 127-1.1-.333-20.331-.667-40.669-1.9-61.9zm3.9 0
        c-.225 11.154-2.035 58.117 1.1 61.9h101
        c9.999.384 29.567 2.395 34.9-2.9-5.759-20.275-1.483-40.579-18-51-25.298-13.682-81.275-7.509-119-8zM59.1 319
        c13.813 1.44 33.901 7.939 43.9 1.1 2.1-5.099 6-11.101 9-18 .667 1.233 1.333 1.567 1.1 1.9 6.906 28.175-2.552 55.413-4.1 86.1 5.425 4.716 34.431 28.963 36 33h-3.9
        c-3.156-7.368-24.599-15.746-31.1-29-1 1.233-2.9 1.567-3.9 1-5.468 47.4-12.821 91.263-3 140.9 8.462 35.011 20.468 75.752 20 113H176
        c.333-26.664.667-53.336.1-80 5.025-21.659 17.681-56.212 11-82.9-1.497-9.795-8.815-18.309-11.1-28.1 5.971 3.377 8.288 6.334 16 9 6.061-4.142 11.352-7.948 17-12.9h1
        c-3.005 11.307-14.192 15.116-17 26.9-2.712 11.382 2.653 51.853 4.1 61.1 3.233 36.563 5.567 72.237 7 107h56
        c2.504-11.501-1.244-38.168 0-43.1 12.664-27.066 20.949-58.843 27-93 1.233-9.666 1.567-19.334 1-29.9 3.588-12.69 5.851-38.812 4.9-48.1-1.995-19.489.765-34.216-9-45.9-9.809 6.786-17.255 18.791-29 21.9 1.333-2 2.667-4.9 3.1-6.9 8.566-5.099 16.234-11.101 23.9-18 .597-14.035 3.857-32.925-5.9-44V341
        c-4.358 1.247-8.87 6.412-18 3.1-26.422-7.109-39.178-65.808-64.1-81 .333 55.895.667 110.906.1 165.9 15.089.236 31.001.161 40-3v.1
        c-1.725 4.734-5.724 9.833-11 11 1.233-.767 1.567-2.433 1-5-21.431 1.233-43.769 1.567-66.1 1.9 4.301 4.692 10.136 9.013 11.1 15.1h-1
        c-.767-.433-2.434-1.767-4.1-4-4.009-8.368-14.011-12.607-19-22 4.85 1.984 5.108 4.555 7.1 5.9H191
        c.596-51.905 5.006-110.652-2.9-162-9.862 3.223-14.076 17.773-20 26.1-10.16 21.212-22.693 41.741-39.1 56.9-2.333-.333-4.667-.667-7-1.9-2-.433-4.9-1.767-6.9-4V341h1
        c2 1.1 4.9 4 6 6h1
        c14.986-2.124 18.656-15.896 24.9-26.9 13.538-21.009 27.212-43.641 43-64-.333-9.766-.667-20.434-1.9-31.1-41.762.333-84.438.667-128 1 2.381 9.998-.434 20.951-3.1 33.1.667 20.565 1.333 40.235 1.1 59.9zM329 225
        c-44.609-.373-83.05.085-135 0v34l17 18
        c11.868 17.618 32.592 62.085 55 65.1 4.204-2.295 7.723-2.772 10-9 2.297-8.618.418-30.186 8-35 .178 11.169 4.085 13.255 7 19.9 12.999-2 26.001-4.9 39-6.9 8.666-26.656-1.722-59.926-1-86.1zm1.1 239.1
        c10.899-2.433 20.901-5.767 30-10 .784-15.671-5.62-26.855-10-41-4.082-18.062 3.769-37.182-1-58.1-2.096-16.203-8.631-28.52-13.1-42-9.233 5.452-28.516 6.296-40.9 7.1 1.233 2.233 1.567 3.567 1 4-2.433-.433-5.767-1.767-10-4v4
        c5.491 14.529 4.413 34.486 8 48 10.093 30.43 38.95 60.724 36 92z
        M58.1 323
        c-9.459 29.481-5.237 59.736-12.1 94-3 12.332-6.9 24.668-9 37 10.332.667 20.668 1.333 30.1 1.1 3.566-6.099 6.234-13.101 8.9-20.1 9.334-22.057 16.223-51.824 21.1-77.9 2.799-7.375 5.458-23.307 2.9-28-4.557-5.342-31.03-6.183-41.9-6.1zM37 457
        c-6.836 14.974-31.883 31.755-30.9 51h1
        c6.991-4.513 9.651-12.139 17-14-.595 5.679-1.588 34.095 4.9 40.1 4.098-3.893 2.257-18.09 8-21.1-1.027 7.549-2.15 18.442-1 24.1h4
        c1.395-2.365 1.484-7.393 5-9.1.152 5.696-.157 6.049 4.1 6.1h1
        c3.826-4.119 9.077-4.026 11-9 11.986-15.099 12.491-47.416 5.9-67-9.999.233-20.001-.433-30-1.1zm308 43.1
        c1.23 14.526 7.106 27.486 12.1 36.9h5.9v-7.9c1.666 2 3.333 4.9 4.1 6.9 1-.333 2.9-.667 3-1.9 1.567-1.766 2.233-4.434 2.9-7.1 1.666.667 3.333 1.333 4.1 1.1V527
        c5.644-7.494.916-27.966-1.1-37 10.923 1.851 12.11 9.539 18.1 15h3v-1
        c-8.233-18.658-15.586-36.759-33.1-47-10.999 3.1-22.001 8-33.9 11.1-.645 19.052.24 37.804 12 48.9 1.099-6.27.574-11.202-2-15.9v-1h4.9z
        M175 654
        c-15.525-.246-40.834-5.887-53 0-.696 20.908-9.41 42.493-5.9 67 6.609 31.724 11.575 63.086 19.9 90.1 4.021 14.382-2.294 28.164 4 38 10.999.233 22.001-.433 33-1.1-2-5.333-4.9-10.667-6-16-8.011-26.245 9.602-82.899 14-102 4.636-20.135-3.609-38.839-8-53l2-23
        "

                @click="changeFlagbyId(9)"
              />

              <path
                id="circle7"
                d="
            zm36 202h39.1
        c-5.351-36.659 9.516-84.075 16.9-121 6.039-30.198-8.447-55.598-6.9-81-17.432.333-35.769.667-55 .1 9.477 26.616-6.468 45.914-2 75.9 5.994 28.457 7.323 56.804 12 86 3.892 15.664-4.007 24.273-4.1 40z
            "
                stroke="none"
                fill="primary"
                fill-rule="evenodd"
                @click="changeFlagbyId(14)"
              />
            </g>
          </svg>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
    name: 'Report',
    data() {
        return {
            color: 'orange',

            // colorS: '#fbfbfb',
            // colorS: '#df0404',
            colorS: '#016f10',
            flag: true,
            width: 100,
            height: 100,
            color1: '#7d7d7d',
            color2: '#7d7d7d',
            color3: '#7d7d7d',
            color4: '#7d7d7d',
            color5: '#7d7d7d',
            color6: '#7d7d7d',
            color7: '#7d7d7d',
            color8: '#7d7d7d',
            color9: '#7d7d7d',
            color10: '#7d7d7d',
            color11: '#7d7d7d',
            color12: '#7d7d7d',
            color13: '#7d7d7d',
            color14: '#7d7d7d',
            color15: '#7d7d7d',
            color16: '#7d7d7d',
            color17: '#7d7d7d',
            color18: '#7d7d7d',
            color19: '#7d7d7d',
            color20: '#7d7d7d',
            color21: '#7d7d7d',
            color22: '#7d7d7d',
            color23: '#7d7d7d',
            color24: '#7d7d7d',
            color25: '#7d7d7d',
            color26: '#7d7d7d',
            color27: '#7d7d7d',
            color28: '#7d7d7d',
            color29: '#7d7d7d',
            color30: '#7d7d7d',
            score1: 0,
            score2: 0,
            score3: 0,
            score4: 0,
            score5: 0,
            score6: 0,
            score7: 0,
            score8: 0,
            score9: 0,
            score10: 0,
            score11: 0,
            score12: 0,
            score13: 0,
            score14: 0,
            score15: 0,
            score16: 0,
            score17: 0,
            score18: 0,
            score19: 0,
            score20: 0,
            score21: 0,
            score22: 0,
            score23: 0,
            score24: 0,
            score25: 0,
            score27: 0,
            score28: 0,
            score29: 0,
            score30: 0,
            stroke: 0,
            colors: ['#a7a8ac', '#025b11', '#a0b503', '#ee9d03', '#e2562a', '#f90704', '#555255', '#1e1917'],
            score: [0, 1, 1.5, 2, 2.5, 3, 4, 5],
            scoreStandard: [
                {
                    name: '0',
                    value: 0,
                },
                {
                    name: '1',
                    value: 1,
                },
                {
                    name: '1.5',
                    value: 1.5,
                },
                {
                    name: '2',
                    value: 2,
                },
                {
                    name: '2.5',
                    value: 2.5,
                },
                {
                    name: '3',
                    value: 3,
                },
                {
                    name: '4',
                    value: 4,
                },
                {
                    name: '5',
                    value: 5,
                },
            ],
            countTrack: [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
            ],
            scoreTrack: [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
            ],
            colorTrack: [
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
                '#7d7d7d',
            ],
        }
    },
    methods: {
        toggleStroke() {
            this.stroke == 0 ? (this.stroke = 5) : (this.stroke = 0)
        },
        changeFlag() {
            this.flag = !this.flag
        },
        async changeFlagbyId(pathId) {
            if (this.countTrack[pathId] === 7) {
                this.countTrack[pathId] = 0
            } else {
                this.countTrack[pathId] += 1
            }
            const currentCount = this.countTrack[pathId]
            this.scoreTrack[pathId] = this.score[currentCount]
            this.colorTrack[pathId] = this.colors[currentCount]
            console.log(pathId)
            if (pathId === 1) {
                this.color1 = this.colors[currentCount]
                this.score1 = this.score[currentCount]
            }
            if (pathId === 2) {
                this.color2 = this.colors[currentCount]
                this.score2 = this.score[currentCount]
            }
            if (pathId === 3) {
                this.color3 = this.colors[currentCount]
                this.score3 = this.score[currentCount]
            }

            if (pathId === 4) {
                this.color4 = this.colors[currentCount]
                this.score4 = this.score[currentCount]
            }
            if (pathId === 5) {
                this.color5 = this.colors[currentCount]
                this.score5 = this.score[currentCount]
            }
            if (pathId === 6) {
                this.color6 = this.colors[currentCount]
                this.score6 = this.score[currentCount]
            }
            if (pathId === 7) {
                this.color7 = this.colors[currentCount]
                this.score7 = this.score[currentCount]
            }
            if (pathId === 8) {
                this.color8 = this.colors[currentCount]
                this.score8 = this.score[currentCount]
            }
            if (pathId === 9) {
                this.color9 = this.colors[currentCount]
                this.score9 = this.score[currentCount]
            }
            if (pathId === 10) {
                this.color10 = this.colors[currentCount]
                this.score10 = this.score[currentCount]
            }
            if (pathId === 11) {
                this.color11 = this.colors[currentCount]
                this.score11 = this.score[currentCount]
            }
            if (pathId === 12) {
                this.color12 = this.colors[currentCount]
                this.score12 = this.score[currentCount]
            }
            if (pathId === 13) {
                this.color13 = this.colors[currentCount]
                this.score13 = this.score[currentCount]
            }
            if (pathId === 14) {
                this.color14 = this.colors[currentCount]
                this.score14 = this.score[currentCount]
            }
            if (pathId === 15) {
                this.color15 = this.colors[currentCount]
                this.score15 = this.score[currentCount]
            }
            if (pathId === 16) {
                this.color16 = this.colors[currentCount]
                this.score16 = this.score[currentCount]
            }
            if (pathId === 17) {
                this.color17 = this.colors[currentCount]
                this.score17 = this.score[currentCount]
            }
            if (pathId === 18) {
                this.color18 = this.colors[currentCount]
                this.score18 = this.score[currentCount]
            }
            if (pathId === 19) {
                this.color19 = this.colors[currentCount]
                this.score19 = this.score[currentCount]
            }
            if (pathId === 20) {
                this.color20 = this.colors[currentCount]
                this.score20 = this.score[currentCount]
            }
            if (pathId === 21) {
                this.color21 = this.colors[currentCount]
                this.score21 = this.score[currentCount]
            }
            if (pathId === 22) {
                this.color22 = this.colors[currentCount]
                this.score22 = this.score[currentCount]
            }
            if (pathId === 23) {
                this.color23 = this.colors[currentCount]
                this.score23 = this.score[currentCount]
            }
            if (pathId === 24) {
                this.color24 = this.colors[currentCount]
                this.score24 = this.score[currentCount]
            }
            if (pathId === 25) {
                this.color25 = this.colors[currentCount]
                this.score25 = this.score[currentCount]
            }
            if (pathId === 26) {
                this.color26 = this.colors[currentCount]
                this.score26 = this.score[currentCount]
            }
            if (pathId === 27) {
                this.color27 = this.colors[currentCount]
                this.score27 = this.score[currentCount]
            }
            if (pathId === 28) {
                this.color28 = this.colors[currentCount]
                this.score28 = this.score[currentCount]
            }
            if (pathId === 29) {
                this.color29 = this.colors[currentCount]
                this.score29 = this.score[currentCount]
            }
            if (pathId === 30) {
                this.color30 = this.colors[currentCount]
                this.score30 = this.score[currentCount]
            }
        },
    },
}
</script>
