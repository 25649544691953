<template>
  <div>
    <!-- <v-btn
      class="tw--ml-6"
      elevation="5"
      :color="statusColor[`${visitStore.faxStatus ? visitStore.faxStatus.status : 'warning'}`]"
      :to="{name: 'transfer'}"
    >
      <v-icon
        left
        color="white"
      >
        {{ icons.mdiCursorDefaultClickOutline }}
      </v-icon>
      {{ visitStore.faxStatus ? statusLabel[visitStore.faxStatus.status] : 'Pending' }}
    </v-btn> -->
    <v-row>
      <v-col v-if="this.visitStore.visitData.status[1].value === 'action'">
        <div>
          <address-dialog
            :is-main-bio-dialog-open="addressDialogStatus"
          >
          </address-dialog>
          <!-- <button
            class="tw-w-full sm:tw-w-auto tw-mt-12 md:tw-mt-10 tw-bg-red-500 tw-py-3 tw-px-6 tw-text-base tw-font-medium tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-800 hover:tw-bg-gray-700"
            @click="nextStep($event)"
          >
            <v-icon
              left
              color="white"
            >
              {{ icons.mdiCursorDefaultClickOutline }}
            </v-icon>
            Click Here <v-icon
              dark
              right
            >
              {{ icons.mdiArrowRight }}
            </v-icon>
          </button> -->
        </div>
      </v-col>
      <!--  && (!this.visitStore.faxStatus && !this.visitStore.faxStatus.status) -->
      <v-col v-if="this.visitStore.visitData.transferId ===undefined && ( this.visitStore.visitData.status[1].value === 'inprogress' || (this.visitStore.visitData.status[1].value === 'complete' && this.visitStore.visitData.status[2].value === 'pending') || (this.visitStore.visitData.status[1].value === 'pending' && this.visitStore.visitData.status[0].value === 'complete') || this.visitStore.visitData.status[1].value === 'transfer')">
        <processing-details
          :text-label="textLabel"
          :is-main-bio-dialog-open="processingDialog"
        ></processing-details>
      </v-col>
      <v-col v-if="this.visitStore.visitData.status[0].value === 'pending' || this.visitStore.visitData.status[1].value === 'pending'">
        <profile-action
          :is-main-bio-dialog-open="isMainBioDialogOpen"
          :emit-step="startingPoint"
          :process-step="processStep"
        ></profile-action>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiArrowRight, mdiCursorDefaultClickOutline } from '@mdi/js'
import createProfileForm from './createProfileForm.vue'
import profileAction from '@/components/Forms/profileAction.vue'
import { useVisitStore } from '@/stores/Visit.js'
import { messageList, statusColor, statusLabel } from '@/assets/lists'
import processingDetails from '@/components/Forms/processingDetails.vue'
import { getCurrentTimestamp, getTimeDifference } from '@/util/functions/index.js'
import addressDialog from '@/components/magicButton/addressDialog.vue'

export default {
    name: 'FormDialogBtn',
    components: {
        // createProfileForm,
        profileAction,
        processingDetails,
        addressDialog
    },
    props: {
        isMainBioDialogOpenProp: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            addressDialogStatus: false,

            // startingPoint: 1,
        }
    },
    computed: {
        disabled() {
            if (getTimeDifference(this.visitStore.visitData.status[1].timeStamp) > 15) {
                return true
            }

            return false
        },
        textLabel() {
            return this.visitStore.visitData.status[1].value === 'transfer' ? 'Switch Pharmacy' : 'Pharmacy Selector'
        },
        processStep() {
            return (this.visitStore.visitData.status[0].value === 'complete' && this.visitStore.visitData.status[1].value === 'pending') ? 6 : 5
        },
        startingPoint() {
            // if (this.visitStore.visitData.status[0].value === 'complete') {
            //     return 4
            // }

            return 1
        },
    },
    setup() {
        const visitStore = useVisitStore()
        const isMainBioDialogOpen = ref(false)
        const processingDialog = ref(false)

        return {
            statusLabel,
            messageList,
            processingDialog,
            statusColor,
            visitStore,
            isMainBioDialogOpen,
            icons: { mdiArrowRight, mdiCursorDefaultClickOutline }
        }
    },
    mounted() {
        this.$root.$on('updateMainBioDialog', data => {
            this.isMainBioDialogOpen = data
        })
    },
    methods: {
        nextStep(e) {
            this.addressDialogStatus = true
            console.log('🚀 ~ file: formDialogBtn.vue ~ line 101 ~ nextStep ~ e', e)

            e.preventDefault()

            // this.$router.push({ name: 'transfer' })
        },
        closeDialogT(val) {
            this.isMainBioDialogOpen = val
        },
    },
}
</script>
