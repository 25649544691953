<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div>
    <v-card flat>
      <div class="tw-m-2 tw-shadow tw-overflow-hidden sm:tw-rounded-md">
        <div class="tw-px-4 tw-py-5 tw-bg-blue-200 tw-bg-opacity-50 sm:tw-p-6 dark:tw-bg-darkmode">
          <!-- <v-card-title>
        <span class="text-base">{{ ptSigPanel[x].title }} <v-icon
          v-if="this.PatientSignature===''"
          color="warning"
          medium
        >
          {{ icons.mdiAlert }}
        </v-icon></span>
      </v-card-title> -->
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="4"
                sm="12"
              >
                <div class="tw-pb-6">
                  <v-switch
                    v-model="caregiverSwitch"
                    label="Toggle for Caregiver"
                    hide-details
                    @change="toggleCaregiver"
                  ></v-switch>
                </div>
                <div>
                  <v-text-field
                    v-model="patientFullName"
                    class=""
                    :label="ptSigPanel[x].textField"
                    suffix=""
                    outlined
                    hide-details
                  >
                  </v-text-field>
                  <v-radio-group
                    v-model="verbalConsent"
                    column
                  >
                    <!-- <v-radio
                      label="Click here to sign as COVID-19 Verbal Consent"
                      value="Yes"
                      @click="injectCovidConsent"
                    ></v-radio> -->
                    <v-radio
                      label="Click here to sign a Typed Signature"
                      value="No"
                      @click="injectPtSignature"
                    ></v-radio>
                    <v-radio
                      label="Signature Pad"
                      value="pad"
                      @click="injectPtSignature"
                    ></v-radio>
                  </v-radio-group>
                  <div>
                    <v-menu
                      v-model="patientDateSignedMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="patientDateSigned"
                          label="Date Signed"
                          :prepend-icon="icons.mdiCalendar"
                          readonly
                          v-bind="attrs"
                          outlined
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="patientDateSigned"
                        color="primary"
                        @input="patientDateSignedMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="8"
                sm="12"
              >
                <div v-if="verbalConsent != 'pad'">
                  <div>
                    <div
                      id="signatureTextTyped"
                      class="tw-place-items-center tw-justify-self-auto tw-content-center tw-h-64 tw-grid tw-grid-rows-1  tw-bg-transparent  tw-justify-center"
                    >
                      <p
                        id="signatureTextFont"
                        class="tw-capitalize tw-px-8 tw-mx-8 tw-pb-10 tw-mb-6 tw-text-center tw-bg-transparent tw-italic tw-font-serif tw-text-5xl tw-text-black"
                      >
                        {{ patientFullName }}
                      </p>
                    </div>
                  </div>
                  <div class=" tw-bg-transparent tw-flex tw-justify-center">
                    <v-btn
                      color="success"
                      @click="printPartOfPage"
                    >
                      <v-icon
                        v-if="this.PatientSignature===''"
                        color="error"
                        medium
                      >
                        {{ icons.mdiAlert }}
                      </v-icon>
                      <span v-if="this.PatientSignature===''">&nbsp &nbsp</span>
                      Insert Electronic Signature
                    </v-btn>
                  </div>
                </div>
                <div v-if="verbalConsent === 'pad'">
                  <div class="Signaturecontainer">
                    <div class="Signaturecontainer">
                      <div
                        class="d-flex justify-center align-center"
                      >
                        <VueSignaturePad
                          id="signature"
                          ref="signaturePad"
                          class="border-solid border-4 border-black"
                          width="auto"
                          height="200px"
                          :options="{ onBegin, onEnd }"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="buttons">
                    <v-btn
                      color="primary"
                      @click="undoPharmacistSignature"
                    >
                      Undo
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <div
            v-if="this.injected"
            class="tw-p-4"
          >
            <v-alert

              color="primary"
              dark
            >
              Injected Successfully.
            </v-alert>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>

import {
    mdiAlert,
    mdiArrowExpand,
    mdiCalendar,
} from '@mdi/js'
import {
    UltimateTextToImage
} from 'ultimate-text-to-image'

import { ref } from '@vue/composition-api'
import { useVisitStore } from '@/stores/Visit.js'

import { changeNameCase, formatTelNum, convertUTCDateToLocalDate } from '@/util/functions/index'

export default {
    name: 'SignaturePanel',
    components: {},
    setup() {
        const visitStore = useVisitStore()
        const patientDateSigned = convertUTCDateToLocalDate(new Date())
        const patientDateSignedMenu = ref(false)
        const caregiverSwitch = ref(false)

        return {
            caregiverSwitch,
            patientDateSigned,
            visitStore,
            patientDateSignedMenu,
            changeNameCase,
            convertUTCDateToLocalDate,
            formatTelNum,
        }
    },

    data() {
        return {
            ptSigPanel: [
                {
                    title: 'Patient Signature',
                    textField: 'Patient Full Name',
                },
                {
                    title: 'Caregiver Signature',
                    textField: 'Caregiver Full Name',
                },
            ],
            verbalConsent: 'No',
            patientFullName: `${changeNameCase(this.visitStore.patientData.firstName) || ''} ${changeNameCase(this.visitStore.patientData.lastName) || ''}` || '',
            rphSearchDialog: false,
            rphNameKey: 0,
            injected: false,
            PatientSignature: '',
            icons: {
                mdiAlert,
                mdiArrowExpand,
                mdiCalendar,
            },
        }
    },
    computed: {
        x() {
            if (this.caregiverSwitch) {
                return 1
            }

            return 0
        },
        formData() {
            const data = {}
            data.patientName = this.patientFullName
            data.patientDateSigned = this.patientDateSigned || ''

            if (this.caregiverSwitch) {
                data.caregiverDateSigned = this.patientDateSigned || ''
                data.agentName = this.patientFullName || ''
                data.patientDateSigned = ''
                data.agentSignature = this.PatientSignature || ''
                data.ptSignature = ''
            }
            if (!this.caregiverSwitch) {
                data.ptSignature = this.PatientSignature || ''
                data.caregiverDateSigned = ''
                data.agentName = ''
                data.agentSignature = ''
            }

            return data
        },
    },

    watch: {
        formData() {
            this.$emit('updateSigPanel', this.formData)
        }
    },
    destroyed() {
        this.$root.$off('updateSigPanel')
    },
    mounted() {
        this.$root.$on('Refresh_Table', () => {
            this.patientFullName = `${changeNameCase(this.visitStore.patientData.firstName) || ''} ${changeNameCase(this.visitStore.patientData.lastName) || ''}` || ''
        })

        //
    },
    methods: {
        toggleCaregiver() {
            this.$emit('toggleCaregiver', this.ptSigPanel[this.x])
        },
        injectCovidConsent() {
            this.patientFullName = 'COVID-19: Verbal Consent'

            // this.printPartOfPage()
        },
        injectPtSignature() {
            this.patientFullName = `${changeNameCase(this.visitStore.patientData.firstName) || ''} ${changeNameCase(this.visitStore.patientData.lastName) || ''}` || ''

            // this.printPartOfPage()
        },
        async printPartOfPage() {
            const textToImage = new UltimateTextToImage(`${this.patientFullName}`, {
                width: 600,
                fontFamily: 'Robertson',
                fontSize: 80,
                fontStyle: 'italic',
                marginTop: 55,
                noAutoWrap: true,
            }).render()
            const dataUrlPng = textToImage.toDataUrl()
            this.PatientSignature = dataUrlPng
            this.injected = true
            setTimeout(() => {
                this.injected = false
            }, 1500)
        },
        onEnd() {
            this.savePharmacistSignature()
        },
        savePharmacistSignature() {
            const {
                isEmpty,
                data
            } = this.$refs.signaturePad.saveSignature()
            this.PatientSignature = data
        },
        undoPharmacistSignature() {
            this.$refs.signaturePad.undoSignature()
        },

        hideSignaturePanel() {
            this.signatureStatus = false
        },
        onBegin() {},
    },
}
</script>

<style lang="scss" scoped>
@import '@/styles/signaturePad.scss';
</style>
